import { defineStore } from "pinia";
import { Option } from "../types/index_types";
import { ref } from "vue";

export const useClockStore = defineStore("clocksheet", {
  state: () => ({
    taskName: "",
    selectedCategoryOption: {
      id: 0,
      label: "",
      value: {
        project_based: false
      }
    },
    categoriesOptions: [] as Option[],
    projectsOptions: [] as Option[],
    selectedProjectOption: {
      id: 0,
      label: "",
      value: {
        project_based: false
      }
    },
    showAdditionalClockingTime: false,
    timerAtZero: false,
    hours: ref(0),
    minutes: ref(0),
    seconds: ref<number | string >(0),
    isAutomatic: false,
    startTimer: false,
    submitCalculatedTime: ref<number | null>(null),
    checking: true,
    startTimeForAutomatedClocking: ref(),
    audio: ref<HTMLAudioElement>(),
    sound: ref(""),
    runningTimeContext:ref<number | undefined>(),
   countdownStarted: false
  }),
  persist:true
});
